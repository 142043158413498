// Enum for button variants
export enum ButtonVariantEnum {
  Outlined = 'outlined',
  Filled = 'filled',
}

// Enum for slider button types
export enum SliderButtonTypeEnum {
  Next = 'next',
  Prev = 'prev',
}

// Enum for button types
export enum ButtonTypeEnum {
  Button = 'button',
  Submit = 'submit',
}
