const DEVELOPMENT_STAGE: string = 'production';

export const CAROUSEL_FAST_DURATION: number = 25;
export const CAROUSEL_SLOW_DURATION: number = 75;

export const FACEBOOK_LINK: string = 'https://www.facebook.com/';
export const INSTAGRAM_LINK: string = 'https://www.instagram.com/';
export const WHATSAPP_LINK: string = 'https://wa.me/';

export const SELECTED_COLOR: string = '#2844F3';

export const HOME_DELAY_BEFORE_ANIMATION: number = 0.2;
export const HOME_TRANSITION_ANIMATION: number = 0.2;
export const EQUIPMENT_DELAY_BEFORE_ANIMATION: number = 0.3;
export const EQUIPMENT_TRANSITION_ANIMATION: number = 0.2;
export const SERVICES_TRANSITION_ANIMATION: number = 0.2;
export const SERVICES_DELAY_BEFORE_ANIMATION: number = 0.1;
export const PROJECTS_TRANSITION_ANIMATION: number = 0.4;

export const EMAIL: string = 'info@butterflyae.com';
export const PHONE_NUMBER: string = '058-608-5995';

export const API_URL: string = DEVELOPMENT_STAGE === 'production' ? 'https://butterflyae.com/api' : 'http://localhost:2000/api';

export const MAX_UPLOAD_IMAGE_SIZE: number = 4000000; // 4 MB
export const FILE_TYPE_IMAGE: string = 'image.*';

export const SIDE_MENU_WIDTH: number = 340;

export const MOBILE_WIDTH: number = 430;
export const TABLET_WIDTH: number = 1024;
